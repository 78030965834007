body {
    background-image: linear-gradient(90deg, #2a627b 0%, #75ccac 50%, #2a627b 87%);
}

.container h4 {
    text-align: center;
    margin-bottom: 3px;
    height: 5px;
    color: #05243a;
}
.container p {
    text-align: center;
    color: #00011f;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
}
.col-md {
    margin-top: 40px;
}
.container img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 25px;
    border: 3px solid #05243a;
    width: 250px;
    height: 250px;
}
#released {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 25px;
    border: 3px solid #73AD21;
    width: 250px;
    height: 250px;
}

.selectpicker {
    background-color: transparent;
    text-align: center;
    border-radius: 1px;
    border-color: transparent;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-style: italic;
    color: #00011f;
    font-size: medium;
}
