:root {
    --primary: #86e5b9;
}
.btn{
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    
}

.btn:hover{
    padding: 8px 20px;
    transition: all 0.3sec ease-out;
    background: #fff;
    color: #6568f4;
    transition: 250ms;
}

.btn--primary{
    background-color: var(--primary);
}
.btn--outline{
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border-radius: 4px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;

}

.btn--medium{
    padding: 8px 20px;
    border-radius: 4px;
    font-size: 18px;
    color: #fff;
}

.btn--large{
    padding: 12px 26px;
    border-radius: 4px;
    font-size: 20px;
    color: #fff;
}