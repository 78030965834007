body {
    background-image: linear-gradient(90deg, #2a627b 0%, #75ccac 50%, #2a627b 87%);
}
#errortitle {
    text-align: center;
    color: #05243a;
    margin-top: 30px;
    font-size: 40px;
    margin-bottom:20px;
}
.returntext {
    text-align: center;
    color: #05243a;
    font-size: 25px;
    font-style: italic;
    margin-top: 15px;
}
#homeButton {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}