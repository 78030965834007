.container img#instagram {
    margin-top:60px;
    border: none;
    width: 250px;
    height: 250px;
}
.container img#email {
    margin-top: 60px;
    border: none;
    width: 250px;
    height: 250px;
}