.NavbarItems {
    background: #05243a;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

a.nounderline {
    text-decoration: none;
    color: #ffffff;
}
.navbar-logo{
    color: #ffffff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.fa-podcast{
    margin-left: 0.5rem;
    font-size: 1.6rem;    
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(5,auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links{
    color: white;
    text-decoration: none;
    padding: 0.5rem 1 rem;
}
.nav-links:hover {
    background-color: #2a627b;
    border-radius: 4px;
    transition: all 0.2 ease-out;
}
.fa-bars{
    color: white;
}

.nav-links-mobile{
    display:none; 
}

.menu-icon{
    display:none; 
}
@media screen and (max-width: 960px){
    .NavbarItems{
        position: relative;
    }
    .nav-menu{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease ;
    }
    .nav-menu.active {
        background: #2a627b ;
        left:0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .nav-links{
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }
    .nav-links:hover {
        background-color: #2a627b;
        border-radius: 0;
    }
    .navbar-logo{
        position: absolute;
        top:0;
        left:0;
        transform: translate(25%, 50%);
    }
    .menu-icon{
        display:block;
        position: absolute;
        top: 0;
        right:0;
        transform: translate(-100%,60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
    .fa-times{
        color:#fff;
        font-size: 2rem;
    }
    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #5ba99c;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;
    }
    .nav-links-mobile:hover {
        background: #4c9592;
        color: #fff;
        transition: 250ms;
    }

    Button{
        display:none;
    }
    a.nounderline {
        text-decoration: none;
        color: #ffffff;
        text-align:center;
    }
    a.nounderline{
        font-size: 20px;
    }
    select.selectpicker{
        justify-content:center;
        align-content: center;
    }
}